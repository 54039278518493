import React from "react";
import NetworkHero from "../NetworkHero/NetworkHero";
import ResourcePartner from "../../components/ResourcePartner/ResourcePartner";
import { resourcePartnerContainer } from "./ResourcePartners.module.css";

export default function ResourcePartners() {
  const [partners, setPartners] = React.useState();
  const [ref, setRef] = React.useState();

  const getRef = () => {
    fetch('https://conquest.prismic.io/api/v2/',
        {
            method: 'GET',
        }
    ).then(response => 
        response.json().then(data => ({
            data: data,
            status: response.status
        }) 
    ).then(res => {
        if (res.data) {
            getData(res.data.refs[0].ref)
        } else {
            alert("Authentication failed. Please double check your password.");
        }
    }))
}

  const getData = (key) => {
      fetch('https://conquest.prismic.io/api/v2/documents/search?ref='+key+'&access_token=MTY0NTI2NjYzMDcxOC5ZZ3ZQaVJFQUFDSUFSR05x.77-977-977-977-977-977-9CA9f77-9KO-_vULvv73vv70dEmvvv70J77-9LQok77-977-977-977-977-977-977-977-9&state=m4RTEd4rrZ',
          {
              method: 'GET',
          }
      ).then(response => 
          response.json().then(data => ({
              data: data,
              status: response.status
          }) 
      ).then(res => {
          if (res.data) {
            setPartners(res.data.results);
          } else {
              alert("Authentication failed. Please double check your password.");
          }
      }))
  }

  React.useEffect(() => {
      getRef();
  }, [])

  return (
    <>
      <NetworkHero
        img1="https://www.nextbigbrand.in/wp-content/uploads/2019/04/fresh.jpg"
        img2="https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/Amazon_Web_Services_Logo.svg/1024px-Amazon_Web_Services_Logo.svg.png"
        img3="https://www.gmass.co/blog/images/gmass_logo.png"
        img4="https://growthjunkie.com/wp-content/uploads/2018/03/protologo.png"
        img5="https://images.g2crowd.com/uploads/product/image/social_landscape/social_landscape_75532e22dd3818db4fe88080dbac7300/xtensio.png"
        img6="https://user-images.strikinglycdn.com/res/hrscywv4p/image/upload/c_limit,fl_lossy,h_630,w_1200,f_auto,q_auto/816524/Applyifi_square_for_twitter_smaller_ipdupx.png"
        meet="meet our"
        context="partners"
        desc="Our resource partners ensure that our cohort receives full support in different walks of building a strong business, and nothing is keeping them from head-starting their journey in the entrepreneurial world."
      />
      <div className={resourcePartnerContainer}>
      {partners ? <>
        {partners.map((p, id) => (
          <>
            {p.type === "resource_partner" ?
            <>
              <ResourcePartner type={p.data.type[0].text} list={p.data.list} />
            </> : null}
          </>
          
        ))}
        </> : null}
        
      </div>
    </>
  );
}
