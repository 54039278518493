import { Link } from "gatsby";
import React from "react";
import {
  line,
  resouceContainer,
  resourceType,
  resourceItemContainer,
  resourceLogo,
  resourceItem,
} from "./ResourcePartner.module.css";

export default function ResourcePartner(props) {
  return (
    <div className={resouceContainer}>
      <h2 className={resourceType}>{props.type} SOLUTIONS</h2>
      <hr className={line} />
      <div className={resourceItemContainer}>
        {props.list.map((item) => (
          <Link className={resourceItem} href={item.link.url}>
            <img className={resourceLogo} src={item.logo.url} />
            <h3>
              {item.start[0].text} {item.end[0] ? <span>{item.end[0].text}</span> : null}
            </h3>
            <p>{item.description[0].text}</p>
          </Link>
        ))}
      </div>
    </div>
  );
}
